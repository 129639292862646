'use client';

import HydrateAtoms from './state/HydrateAtoms';
import { type ShopifySellingPlan } from '@features/api/shopify/types';
import { selectedSellingPlanAtom } from '../SubscriptionListing/state/sellingPlanAtom';

type HydrateAtomsProps = {
	initialSellingPlan: ShopifySellingPlan;
	children: React.ReactNode;
};

function SubscriptionPlanToggleTabs({ initialSellingPlan, children }: HydrateAtomsProps) {
	return (
		<HydrateAtoms atomValues={[[selectedSellingPlanAtom, initialSellingPlan]]}>{children}</HydrateAtoms>
	);
}

export default SubscriptionPlanToggleTabs;
