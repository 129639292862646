import imageFragment from './image';
import seoFragment from './seo';

const productFragment = /* GraphQL */ `
	fragment product on Product {
		id
		handle
		availableForSale
		title
		description
		descriptionHtml
		options {
			id
			name
			values
		}
		priceRange {
			maxVariantPrice {
				amount
				currencyCode
			}
			minVariantPrice {
				amount
				currencyCode
			}
		}
		variants(first: 250) {
			edges {
				node {
					id
					title
					availableForSale
					selectedOptions {
						name
						value
					}
					price {
						amount
						currencyCode
					}
					compareAtPrice {
						amount
						currencyCode
					}
				}
			}
		}
		featuredImage {
			...image
		}
		images(first: 20) {
			edges {
				node {
					...image
				}
			}
		}
		seo {
			...seo
		}
		tags
		updatedAt
		requiresSellingPlan
		sellingPlanGroups(first: 20) {
			edges {
				node {
					appName
					name
					options {
						name
						values
					}
					sellingPlans(first: 20) {
						edges {
							node {
								description
								name
								id
								priceAdjustments {
									adjustmentValue {
										... on SellingPlanFixedAmountPriceAdjustment {
											__typename
											adjustmentAmount {
												amount
												currencyCode
											}
										}
										... on SellingPlanFixedPriceAdjustment {
											__typename
											price {
												amount
												currencyCode
											}
										}
										... on SellingPlanPercentagePriceAdjustment {
											__typename
											adjustmentPercentage
										}
									}
									orderCount
								}
							}
						}
					}
				}
			}
		}
	}
	${imageFragment}
	${seoFragment}
`;

export default productFragment;
