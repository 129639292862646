import type { MaturaDownloaderGenerateZipRequestRequest } from '@features/api/client';
import useApiClient from '@features/api/useApiClient';
import { useMutation } from '@tanstack/react-query';

function useGenerateMaturaDownloaderZip() {
	const { sharedApiClient } = useApiClient();
	return useMutation({
		mutationFn: async (body: MaturaDownloaderGenerateZipRequestRequest) => {
			return sharedApiClient
				.generateMaturaDownloaderZip({ maturaDownloaderGenerateZipRequestRequest: body })
				.then((res) => res.data);
		},
	});
}

export default useGenerateMaturaDownloaderZip;
