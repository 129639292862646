const vimeo = {
	base: ['vimeo'],
	folders: () => [...vimeo.base, 'folders'],
} as const;

const googleDrive = {
	base: ['google-drive'],
	folders: () => [...googleDrive.base, 'folders'],
} as const;

const trello = {
	base: ['trello'],
	boards: () => [...trello.base, 'boards'],
} as const;

const problemSourceListing = {
	base: ['problem-source-listing'],
	list: () => [...problemSourceListing.base, 'list'],
} as const;

const subject = {
	base: ['subject'],
	list: () => [...subject.base, 'list'],
} as const;

const problemSource = {
	base: ['problem-source'],
	problemsImport: () => [...problemSource.base, 'problems-import'],
} as const;

const product = {
	base: ['product'],
	single: (id: number) => [...product.base, id],
	list: () => [...product.base, 'list'],
};

const shopifyWebhooks = {
	base: ['shopify-webhooks'],
	list: () => [...shopifyWebhooks.base, 'list'],
};

const viewedLessons = {
	base: ['viewed-lessons'],
	single: (id: number) => [...viewedLessons.base, id],
	list: (courseModuleId: number, userId?: number) => [
		...viewedLessons.base,
		'list',
		courseModuleId,
		userId,
	],
};

const formFields = {
	base: ['form-fields'],
	single: (key: string) => [...formFields.base, key],
};

const digitalTextbook = {
	base: ['digital-textbook'],
	single: (id: number) => [...digitalTextbook.base, id],
	list: () => [...digitalTextbook.base, 'list'],
	courses: (id: number) => [...digitalTextbook.single(id), 'courses'],
	section: (id: number, sectionId: number) => [...digitalTextbook.single(id), 'section', sectionId],
	print: (id: number) => [...digitalTextbook.single(id), 'print'],
	sectionPrint: (id: number, sectionId: number) => [
		...digitalTextbook.single(id),
		'section',
		sectionId,
		'print',
	],
};

const workbook = {
	base: ['workbook'],
	section: (sectionId: number) => [...workbook.base, 'section', sectionId],
	sectionInCourse: (courseId: number, sectionId: number) => [
		...workbook.base,
		'section',
		sectionId,
		'in-course',
		courseId,
	],
};

const viewedProblems = {
	base: ['viewed-problems'],
	single: (id: number) => [...viewedProblems.base, id],
	list: ({ listingId, problemSourceId }: { listingId?: number; problemSourceId?: number }) => {
		const base = [...viewedProblems.base, 'list'];

		if (!listingId && !problemSourceId) return base;

		if (listingId) return [...base, 'section-listing', listingId];
		if (problemSourceId) return [...base, 'problem-source', problemSourceId];

		return base;
	},
};

const lastViewedCourseModule = {
	base: ['last-viewed-course-module'],
};

const currentUser = {
	base: ['current-user'],
	single: (id: number) => [...currentUser.base, id],
};

const problems = {
	base: ['problems'],
	single: (id: number) => [...problems.base, id],
};

const nationalPublicData = {
	base: ['national-public-data'],
	universityList: () => [...nationalPublicData.base, 'university-list'],
	universityProgramsList: () => [...nationalPublicData.base, 'university-programs-list'],
	highSchoolList: () => [...nationalPublicData.base, 'high-school-list'],
};

const cart = {
	base: ['cart'],
};

const problemSourceSection = {
	base: ['problem-source-section'],
	list: (problemSourceId: number) => [...problemSourceSection.base, 'list', problemSourceId],
};

export const queryKeys = {
	vimeo,
	googleDrive,
	trello,
	problemSourceListing,
	problemSource,
	subject,
	product,
	shopifyWebhooks,
	viewedLessons,
	viewedProblems,
	lastViewedCourseModule,
	digitalTextbook,
	currentUser,
	workbook,
	problems,
	formFields,
	nationalPublicData,
	cart,
	problemSourceSection,
};
