'use client';
import Link from 'next/link';
import { $path } from 'next-typesafe-url';
import { usePathname } from 'next/navigation';
import { type ComponentProps, type ReactNode } from 'react';

type RegisterLinkWithReturnProps = {
	children: ReactNode;
} & Omit<ComponentProps<typeof Link>, 'href'>;

function RegisterLinkWithReturn({ children, ...rest }: RegisterLinkWithReturnProps) {
	const pathname = usePathname();

	return (
		<Link
			{...rest}
			href={$path({
				route: '/auth/register',
				searchParams: {
					callbackUrl: pathname || '/frontoffice',
				},
			})}>
			{children}
		</Link>
	);
}

export default RegisterLinkWithReturn;
