'use client';

import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import { cn } from '@features/shared/utils/utils';
import { IconCheck } from '@tabler/icons-react';

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
	<CheckboxPrimitive.Root
		ref={ref}
		className={cn(
			'data-[state=checked]:text-orange-600-foreground peer h-5 w-5 shrink-0 rounded-sm border-2 border-gray-800 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary-yellow data-[state=checked]:bg-primary-yellow',
			className
		)}
		{...props}>
		<CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
			<IconCheck className="h-4 w-4" />
		</CheckboxPrimitive.Indicator>
	</CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
