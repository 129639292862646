import { removeFromCart } from '@features/api/shopify';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { queryKeys } from 'settings/queryKeys';

function useRemoveFromCart() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (data: string[]) => {
			let cartId = Cookies.get('cartId');

			if (!cartId) {
				throw new Error('Cart not found');
			}

			const cart = await removeFromCart(cartId, data);
			if (!cart) throw new Error('Cart not found');

			return cart;
		},
		onSuccess: (data) => {
			Cookies.set('cartId', data.id);

			queryClient.setQueryData(queryKeys.cart.base, data);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.cart.base });
		},
	});
}

export default useRemoveFromCart;
