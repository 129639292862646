'use client';

import { useSession } from 'next-auth/react';

function StaffOnly({ children }: { children: React.ReactNode }) {
	const { data: session } = useSession();
	const isStaff = session?.user?.is_staff;

	if (!isStaff) return null;

	return children;
}

export default StaffOnly;
