'use client';

import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '@features/shared/utils/utils';

const Tabs = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Root ref={ref} className={cn('relative', className)} {...props} />
));
Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={cn(
			'mx-auto flex h-auto w-fit items-center gap-3 rounded-md bg-transparent p-1 text-muted-foreground xs:gap-4 sm:gap-6',
			className
		)}
		{...props}
	/>
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
		activeTabValue?: string;
		scrollToActiveTab?: boolean;
	}
>(({ className, activeTabValue, scrollToActiveTab = false, ...props }, ref) => {
	React.useEffect(() => {
		if (!scrollToActiveTab) return;
		if (props.value !== activeTabValue) return;

		const element = document.querySelector(`[id*=-trigger-${activeTabValue}]`);
		element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
	}, []);

	return (
		<TabsPrimitive.Trigger
			ref={ref}
			onClick={(e) =>
				e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' })
			}
			className={cn(
				'inline-flex max-w-52 flex-1 items-center justify-center whitespace-nowrap rounded-[20px] border-[3px] border-solid border-gray-800 px-7 py-2.5 text-base font-bold text-gray-800  ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-gray-800 data-[state=active]:text-white data-[state=active]:shadow-sm xs:px-8 xs:py-3 xs:text-lg sm:px-10 sm:py-4 sm:text-xl',
				className
			)}
			{...props}
		/>
	);
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={cn(
			'mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 data-[state=inactive]:hidden',
			className
		)}
		{...props}
	/>
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
