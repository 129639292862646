import { IconTag } from '@tabler/icons-react';

type DiscountTagProps = {
	title: string | undefined;
};

function DiscountTag({ title }: DiscountTagProps) {
	if (!title) return null;

	return (
		<div className="inline-flex items-center justify-center gap-2 rounded-full border border-solid border-gray-800 bg-transparent px-3 py-1 text-gray-800">
			<IconTag className="h-3 w-3 xs:h-4 xs:w-4" />
			<span className="whitespace-nowrap text-center text-[0.625rem] font-bold xs:text-xs sm:text-sm">
				{title}
			</span>
		</div>
	);
}

export default DiscountTag;
