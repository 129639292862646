'use client';

import type { Cart } from '@features/api/shopify/types';
import { formatMoney } from '@features/shared/utils/formatMoney';
import useGetCart from '../../../../products/data/useGetCart';
import { cn } from '@features/shared/utils/utils';
import { BtnSizeEnum, BtnColorEnum, BtnVariantEnum } from '@features/api/client';
import { Button } from '../../../../shared/ui/Button';
import { IconAlertCircle, IconChevronRight } from '@tabler/icons-react';
import { useRouter } from 'next/navigation';
import { Alert } from '@features/webshop/shared/ui/Alert';
import DiscountTag from '../../parts/DiscountTag/DiscountTag';

function ProductCardTotals({ initalCart, cartError }: { initalCart?: Cart; cartError?: any }) {
	const router = useRouter();
	const { data: cart, isRefetching } = useGetCart({ initialData: initalCart });

	const totalPriceAmountValue = parseFloat(cart?.cost?.totalAmount.amount || '');
	const totalPriceAmount = isNaN(totalPriceAmountValue) ? null : totalPriceAmountValue;
	const totalPrice = cart ? formatMoney(cart?.cost?.totalAmount) : null;
	const cartDiscount =
		cart?.discountAllocations.reduce((sum, discountAllocation) => {
			const amountValue = parseFloat(discountAllocation.discountedAmount.amount);
			const amount = isNaN(amountValue) ? 0 : amountValue;

			return sum + amount;
		}, 0) || 0;

	const lineItemDiscount =
		cart?.lines
			.flatMap((line) => line.discountAllocations)
			.reduce((sum, discountAllocation) => {
				const amountValue = parseFloat(discountAllocation.discountedAmount.amount);
				const amount = isNaN(amountValue) ? 0 : amountValue;

				return sum + amount;
			}, 0) || 0;

	const totalDiscount = cartDiscount + lineItemDiscount;
	const priceBeforeDiscountAmount = totalPriceAmount ? totalPriceAmount + totalDiscount : null;
	const priceBeforeDiscount = formatMoney({ amount: priceBeforeDiscountAmount });
	const shouldShowPriceBeforeDiscount =
		priceBeforeDiscountAmount && totalPriceAmount && priceBeforeDiscountAmount > totalPriceAmount;

	const hasCart = cart && cart.lines.length > 0;
	const hasDiscounts =
		(cart?.discountCodes && cart?.discountCodes?.length > 0) ||
		(cart?.discountAllocations && cart?.discountAllocations.length > 0);

	return (
		<div className="flex w-full flex-col gap-3 xs:gap-4">
			<div
				suppressHydrationWarning
				className={cn('flex items-center justify-end gap-2 sm:gap-4', isRefetching && 'opacity-70')}>
				{shouldShowPriceBeforeDiscount && (
					<span className="text-[22px] font-light line-through xs:text-[22px] sm:text-3xl">
						{priceBeforeDiscount}
					</span>
				)}
				<span className="text-[2rem] font-extrablack leading-none xs:text-3xl sm:text-5xl">
					{totalPrice}
				</span>
			</div>
			{hasDiscounts && (
				<div className="flex flex-col gap-2">
					{cart?.discountCodes?.map((discountCode) => (
						<DiscountTag key={discountCode.code} title={discountCode.code} />
					))}
					{cart?.discountAllocations?.map((discountAllocation) => (
						<DiscountTag
							title={discountAllocation.code || discountAllocation.title}
							key={discountAllocation.code || discountAllocation.title}
						/>
					))}
				</div>
			)}

			{cartError && (
				<Alert variant={'default'} className="flex gap-8">
					<IconAlertCircle />
					<span>Oops,kupnja trenutno nije moguća.</span>
				</Alert>
			)}

			<Button
				size={BtnSizeEnum.Large}
				color={BtnColorEnum.Red}
				variant={BtnVariantEnum.Primary}
				disabled={!hasCart || cartError}
				className="w-full"
				onClick={() => {
					if (!hasCart) return;

					router.push(cart.checkoutUrl);
				}}>
				<span>Kupi pripreme</span>
				<IconChevronRight />
			</Button>
		</div>
	);
}

export default ProductCardTotals;
