import { addToCart, createCart } from '@features/api/shopify';
import type {
	Cart,
	CartItem,
	CartLineAddRequest,
	Product,
	ProductVariant,
} from '@features/api/shopify/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { queryKeys } from 'settings/queryKeys';

const optimisticLineItems = ({
	lines,
	shopifyProductVariant,
	shopifyProduct,
}: {
	lines: Array<CartLineAddRequest>;
	shopifyProductVariant: ProductVariant;
	shopifyProduct: Product;
}) => {
	const transformedLineItems = lines.map((lineItem) => {
		return {
			id: lineItem.merchandiseId,
			quantity: lineItem.quantity,
			discountAllocations: [],

			cost: {
				totalAmount: shopifyProductVariant.price,
				amountPerQuantity: shopifyProductVariant.price,
				compareAtAmountPerQuantity:
					shopifyProductVariant.compareAtPrice || shopifyProductVariant.price,
				subtotalAmount: shopifyProductVariant.price,
			},
			merchandise: {
				id: shopifyProductVariant.id,
				title: shopifyProductVariant.title,
				selectedOptions: shopifyProductVariant.selectedOptions,
				product: shopifyProduct,
			},
		};
	}) satisfies Array<CartItem>;

	return transformedLineItems;
};

function useAddToCart({ shopifyProduct }: { shopifyProduct: Product }) {
	const queryClient = useQueryClient();

	const shopifyProductVariant = shopifyProduct.variants[0];

	return useMutation({
		mutationFn: async (data: Array<CartLineAddRequest>) => {
			let cartId = Cookies.get('cartId');
			let cart = null;

			if (!cartId) {
				cart = await createCart();
				if (!cart) throw new Error('Cart not found');

				cartId = cart.id;
			}

			cart = await addToCart(cartId, data);
			if (!cart) throw new Error('Cart not found');

			return cart;
		},
		onMutate: async (data) => {
			if (!shopifyProductVariant) return;

			await queryClient.cancelQueries({ queryKey: queryKeys.cart.base });
			const previousCart = queryClient.getQueryData(queryKeys.cart.base);

			const lines = optimisticLineItems({ lines: data, shopifyProductVariant, shopifyProduct });

			queryClient.setQueryData(queryKeys.cart.base, (oldCart: Cart) => {
				console.log(oldCart);
				return oldCart
					? {
							...oldCart,
							lines: [...oldCart.lines, ...lines],
						}
					: null;
			});

			return { previousCart };
		},
		onSuccess: (data) => {
			Cookies.set('cartId', data.id);

			queryClient.setQueryData(queryKeys.cart.base, data);
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: queryKeys.cart.base });
		},
		onError: (err, newTodo, context) => {
			console.error(err);
			if (!context?.previousCart) return;

			queryClient.setQueryData(queryKeys.cart.base, context.previousCart);
		},
	});
}

export default useAddToCart;
