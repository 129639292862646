import type { ProblemSourceListWithNumberOfDocuments } from '@features/api/client';
import { uniqueBy, isDefined } from 'remeda';

function normalizeProblemSourcesForDownloaderForm({
	problemSources,
}: {
	problemSources: ProblemSourceListWithNumberOfDocuments[];
}) {
	const sourcesWithDisabledFlag = problemSources.map((source) =>
		source.number_of_documents > 0 ? { ...source, disabled: false } : { ...source, disabled: true }
	);

	const availableYears = uniqueBy(
		problemSources
			.map((source) =>
				source.year
					? {
							label: `${source.year - 1}./${source.year.toString().slice(-2)}.`,
							value: source.year.toString(),
							year: source.year,
						}
					: undefined
			)
			.filter(isDefined),
		(obj) => obj.label
	).map((obj) => ({
		...obj,
		enabled: sourcesWithDisabledFlag.some((source) => source.year === obj.year && !source.disabled),
	}));

	const availableTerms = uniqueBy(
		problemSources
			.map((source) =>
				source.term
					? {
							label: source.term.charAt(0).toUpperCase() + source.term.slice(1),
							value: source.term,
							term: source.term,
						}
					: undefined
			)
			.filter(isDefined),
		(obj) => obj.label
	).map((obj) => ({
		...obj,
		enabled: sourcesWithDisabledFlag.some((source) => source.term === obj.term && !source.disabled),
	}));

	const availableSubjects = uniqueBy(
		problemSources.map((source) => {
			const label = source.level
				? source.problem_source_subject.title + ' ' + source.level
				: source.problem_source_subject.title;

			return {
				label,
				value: source.problem_source_subject.id + (source.level || ''),
				subject_id: source.problem_source_subject.id,
				level: source.level,
				is_mandatory: source.problem_source_subject.is_matura_mandatory,
			};
		}),
		(obj) => obj.label
	).map((obj) => ({
		...obj,
		enabled: sourcesWithDisabledFlag.some(
			(source) => source.problem_source_subject.id === obj.subject_id && !source.disabled
		),
	}));

	const mandatorySubjects = availableSubjects.filter((subject) => subject.is_mandatory);
	const optionalSubjects = availableSubjects.filter((subject) => !subject.is_mandatory);

	return {
		availableYears,
		availableTerms,
		availableSubjects,
		mandatorySubjects,
		optionalSubjects,
	};
}

export default normalizeProblemSourcesForDownloaderForm;
