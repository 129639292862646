import { HIDDEN_PRODUCT_TAG, SHOPIFY_GRAPHQL_API_ENDPOINT, TAGS } from './constants';
import {
	addToCartMutation,
	createCartMutation,
	editCartItemsMutation,
	removeFromCartMutation,
} from './mutations/cart';
import { getBlogPostsQuery, getBlogPostByHandleQuery } from './queries/blog';
import { getCartQuery } from './queries/cart';
import {
	getCollectionByHandleProductsQuery,
	getCollectionByIdProductsQuery,
	getCollectionQuery,
	getCollectionsQuery,
} from './queries/collection';
import { getMenuQuery } from './queries/menu';
import { getPageQuery, getPagesQuery } from './queries/page';
import { getProductQuery, getProductRecommendationsQuery, getProductsQuery } from './queries/product';
import { getShopQuery } from './queries/shop';
import { isShopifyError } from './type-guards';
import type {
	Cart,
	CartLineAddRequest,
	Collection,
	Connection,
	Image,
	Menu,
	Page,
	Product,
	SellingPlanGroup,
	Shop,
	ShopifyAddToCartOperation,
	ShopifyBlogArticle,
	ShopifyBlogArticlesOperation,
	ShopifyCart,
	ShopifyCartOperation,
	ShopifyCollection,
	ShopifyCollectionOperation,
	ShopifyCollectionProductsOperation,
	ShopifyCollectionsOperation,
	ShopifyCreateCartOperation,
	ShopifyMenuOperation,
	ShopifyPageOperation,
	ShopifyPagesOperation,
	ShopifyProduct,
	ShopifyProductOperation,
	ShopifyProductRecommendationsOperation,
	ShopifyProductsOperation,
	ShopifyRemoveFromCartOperation,
	ShopifySellingPlanGroup,
	ShopifyShopOperation,
	ShopifyUpdateCartOperation,
	ShopifyBlogArticleByHandleOperation,
	ShopifyBlog,
} from './types';

import { ensureStartsWith } from './utils';
import { AxiosHeaders } from 'axios';

const domain = process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN
	? ensureStartsWith(process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN, 'https://')
	: '';
const endpoint = `${domain}${SHOPIFY_GRAPHQL_API_ENDPOINT}`;
const key = process.env.NEXT_PUBLIC_SHOPIFY_STOREFRONT_ACCESS_TOKEN!;

type ExtractVariables<T> = T extends { variables: object } ? T['variables'] : never;

export async function shopifyFetch<T>({
	cache = 'force-cache',
	headers,
	query,
	tags,
	variables,
}: {
	// eslint-disable-next-line no-undef
	cache?: RequestCache;
	// eslint-disable-next-line no-undef
	headers?: AxiosHeaders;
	query: string;
	tags?: string[];
	variables?: ExtractVariables<T>;
}): Promise<{ status: number; body: T } | never> {
	try {
		const result = await fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-Shopify-Storefront-Access-Token': key,
				...headers,
			},
			body: JSON.stringify({
				...(query && { query }),
				...(variables && { variables }),
			}),
			cache,
			...(tags && { next: { tags } }),
		});

		const body = (await result.json()) as any;

		if (body.errors) {
			throw body.errors[0];
		}

		return {
			status: result.status,
			body,
		};
	} catch (e) {
		if (isShopifyError(e)) {
			throw {
				cause: e.cause?.toString() || 'unknown',
				status: e.status || 500,
				message: e.message,
				query,
			};
		}

		throw {
			error: e,
			query,
		};
	}
}

const removeEdgesAndNodes = (array: Connection<any>) => array.edges.map((edge) => edge?.node);

const reshapeCart = (cart: ShopifyCart): Cart | undefined => {
	if (!cart) return undefined;

	if (!cart.cost?.totalTaxAmount) {
		cart.cost.totalTaxAmount = {
			amount: '0.0',
			currencyCode: 'EUR',
		};
	}

	return {
		...cart,
		lines: removeEdgesAndNodes(cart.lines),
	};
};

const reshapeCollection = (collection: ShopifyCollection): Collection | undefined => {
	if (!collection) {
		return undefined;
	}

	return {
		...collection,
		path: `/search/${collection.handle}`,
	};
};

const reshapeCollections = (collections: ShopifyCollection[]) => {
	const reshapedCollections = [];

	for (const collection of collections) {
		if (collection) {
			const reshapedCollection = reshapeCollection(collection);

			if (reshapedCollection) {
				reshapedCollections.push(reshapedCollection);
			}
		}
	}

	return reshapedCollections;
};

const reshapeImages = (images: Connection<Image>, productTitle: string) => {
	const flattened = removeEdgesAndNodes(images);

	return flattened.map((image) => {
		const filename = image.url.match(/.*\/(.*)\..*/)[1];
		return {
			...image,
			altText: image.altText || `${productTitle} - ${filename}`,
		};
	});
};

const reshapeSellingPlanGroups = (
	sellingPlanGroups: Connection<ShopifySellingPlanGroup>
): Array<SellingPlanGroup> => {
	const flattened = removeEdgesAndNodes(sellingPlanGroups);

	return flattened.map((group) => {
		return {
			...group,
			sellingPlans: removeEdgesAndNodes(group.sellingPlans),
		};
	});
};

const reshapeProduct = (product: ShopifyProduct, filterHiddenProducts: boolean = true) => {
	if (!product || (filterHiddenProducts && product.tags.includes(HIDDEN_PRODUCT_TAG))) {
		return undefined;
	}

	const { images, variants, sellingPlanGroups, ...rest } = product;

	return {
		...rest,
		images: reshapeImages(images, product.title),
		variants: removeEdgesAndNodes(variants),
		sellingPlanGroups: reshapeSellingPlanGroups(sellingPlanGroups),
	};
};

const reshapeProducts = (products: ShopifyProduct[]) => {
	const reshapedProducts = [];

	for (const product of products) {
		if (product) {
			const reshapedProduct = reshapeProduct(product);

			if (reshapedProduct) {
				reshapedProducts.push(reshapedProduct);
			}
		}
	}

	return reshapedProducts;
};

export async function createCart(): Promise<Cart | undefined> {
	const res = await shopifyFetch<ShopifyCreateCartOperation>({
		query: createCartMutation,
		cache: 'no-store',
	});

	return reshapeCart(res.body.data.cartCreate.cart);
}

export async function addToCart(cartId: string, lines: Array<CartLineAddRequest>): Promise<Cart | undefined> {
	const res = await shopifyFetch<ShopifyAddToCartOperation>({
		query: addToCartMutation,
		variables: {
			cartId,
			lines,
		},
		cache: 'no-store',
	});

	return reshapeCart(res.body.data.cartLinesAdd.cart);
}

export async function removeFromCart(cartId: string, lineIds: string[]): Promise<Cart | undefined> {
	const res = await shopifyFetch<ShopifyRemoveFromCartOperation>({
		query: removeFromCartMutation,
		variables: {
			cartId,
			lineIds,
		},
		cache: 'no-store',
	});

	return reshapeCart(res.body.data.cartLinesRemove.cart);
}

export async function updateCart(
	cartId: string,
	lines: { id: string; merchandiseId: string; quantity: number }[]
): Promise<Cart | undefined> {
	const res = await shopifyFetch<ShopifyUpdateCartOperation>({
		query: editCartItemsMutation,
		variables: {
			cartId,
			lines,
		},
		cache: 'no-store',
	});

	return reshapeCart(res.body.data.cartLinesUpdate.cart);
}

export async function getCart(cartId: string): Promise<Cart | undefined> {
	const res = await shopifyFetch<ShopifyCartOperation>({
		query: getCartQuery,
		variables: { cartId },
		tags: [TAGS.cart],
		cache: 'no-store',
	});

	// Old carts becomes `null` when you checkout.
	if (!res.body.data.cart) {
		return undefined;
	}

	return reshapeCart(res.body.data.cart);
}

export async function getCollection(handle: string): Promise<Collection | undefined> {
	const res = await shopifyFetch<ShopifyCollectionOperation>({
		query: getCollectionQuery,
		tags: [TAGS.collections],
		variables: {
			handle,
		},
	});

	return reshapeCollection(res.body.data.collection);
}

export async function getCollectionProducts({
	collectionHandle,
	collectionId,
	reverse,
	sortKey,
}: {
	collectionHandle?: string;
	collectionId?: number | null;
	reverse?: boolean;
	sortKey?: string;
}): Promise<Product[]> {
	if (!collectionHandle && !collectionId) {
		console.warn(`Either collectionHandle or collectionId must be provided`);
		return [];
	}

	const query = collectionId ? getCollectionByIdProductsQuery : getCollectionByHandleProductsQuery;
	const variables = collectionId
		? { id: `gid://shopify/Collection/${collectionId}` }
		: { handle: collectionHandle };

	const res = await shopifyFetch<ShopifyCollectionProductsOperation>({
		query: query,
		tags: [TAGS.collections, TAGS.products],
		variables: {
			...variables,
			reverse,
			sortKey: sortKey === 'CREATED_AT' ? 'CREATED' : sortKey,
		},
	});

	if (!res.body.data.collection) {
		if (collectionId && collectionHandle) {
			const resWithHandle = await shopifyFetch<ShopifyCollectionProductsOperation>({
				query: getCollectionByHandleProductsQuery,
				tags: [TAGS.collections, TAGS.products],
				variables: {
					handle: collectionHandle,
					reverse,
					sortKey: sortKey === 'CREATED_AT' ? 'CREATED' : sortKey,
				},
			});

			if (!resWithHandle.body.data.collection) {
				console.debug(
					`No collection found for either handle \`${collectionHandle}\` or id \`${collectionId}\``
				);
				return [];
			}

			return reshapeProducts(removeEdgesAndNodes(resWithHandle.body.data.collection.products));
		}

		console.debug(`No collection found for \`${collectionId || collectionHandle}\``);
		return [];
	}

	return reshapeProducts(removeEdgesAndNodes(res.body.data.collection.products));
}

export async function getCollections(): Promise<Collection[]> {
	const res = await shopifyFetch<ShopifyCollectionsOperation>({
		query: getCollectionsQuery,
		tags: [TAGS.collections],
	});
	const shopifyCollections = removeEdgesAndNodes(res.body?.data?.collections);
	const collections = [
		{
			handle: '',
			title: 'All',
			description: 'All products',
			seo: {
				title: 'All',
				description: 'All products',
			},
			path: '/search',
			updatedAt: new Date().toISOString(),
		},
		// Filter out the `hidden` collections.
		// Collections that start with `hidden-*` need to be hidden on the search page.
		...reshapeCollections(shopifyCollections).filter(
			(collection) => !collection.handle.startsWith('hidden')
		),
	];

	return collections;
}

export async function getMenu(handle: string): Promise<Menu[]> {
	const res = await shopifyFetch<ShopifyMenuOperation>({
		query: getMenuQuery,
		tags: [TAGS.collections],
		variables: {
			handle,
		},
	});

	return (
		res.body?.data?.menu?.items.map((item: { title: string; url: string }) => ({
			title: item.title,
			path: item.url.replace(domain, '').replace('/collections', '/search').replace('/pages', ''),
		})) || []
	);
}

export async function getShop(): Promise<Shop> {
	const res = await shopifyFetch<ShopifyShopOperation>({
		query: getShopQuery,
	});

	return res.body.data.shop;
}

export async function getPage(handle: string): Promise<Page> {
	const res = await shopifyFetch<ShopifyPageOperation>({
		query: getPageQuery,
		variables: { handle },
	});

	return res.body.data.pageByHandle;
}

export async function getPages(): Promise<Page[]> {
	const res = await shopifyFetch<ShopifyPagesOperation>({
		query: getPagesQuery,
	});

	return removeEdgesAndNodes(res.body.data.pages);
}

export async function getProduct(handle: string): Promise<Product | undefined> {
	const res = await shopifyFetch<ShopifyProductOperation>({
		query: getProductQuery,
		tags: [TAGS.products],
		variables: {
			handle,
		},
	});

	return reshapeProduct(res.body.data.product, false);
}

export async function getProductRecommendations(productId: string): Promise<Product[]> {
	const res = await shopifyFetch<ShopifyProductRecommendationsOperation>({
		query: getProductRecommendationsQuery,
		tags: [TAGS.products],
		variables: {
			productId,
		},
	});

	return reshapeProducts(res.body.data.productRecommendations);
}

export async function getProducts({
	query,
	reverse,
	sortKey,
}: {
	query?: string;
	reverse?: boolean;
	sortKey?: string;
}): Promise<Product[]> {
	const res = await shopifyFetch<ShopifyProductsOperation>({
		query: getProductsQuery,
		tags: [TAGS.products],
		variables: {
			query,
			reverse,
			sortKey,
		},
	});

	return reshapeProducts(removeEdgesAndNodes(res.body.data.products));
}

const reshapeBlogArticles = (articles: ShopifyBlogArticle[], blogHandle: string) => {
	const reshapedArticles = [];

	for (const article of articles) {
		if (article) {
			reshapedArticles.push({
				...article,
				blogHandle,
			});
		}
	}

	return reshapedArticles;
};

const reshapeBlogs = (blogs: ShopifyBlog[]) => {
	const reshapedBlogs = [];

	for (const blog of blogs) {
		const reshapedArticles = reshapeBlogArticles(removeEdgesAndNodes(blog.articles), blog.handle);
		if (blog) {
			reshapedBlogs.push({
				...blog,
				articles: reshapedArticles,
			});
		}
	}

	return reshapedBlogs;
};

export async function getFirstShopifyBlog() {
	try {
		const res = await shopifyFetch<ShopifyBlogArticlesOperation>({
			query: getBlogPostsQuery,
			tags: [TAGS.blog],
		});

		const blogs = reshapeBlogs(removeEdgesAndNodes(res.body.data.blogs));

		return blogs[0];
	} catch (error) {
		console.error('Failed to fetch blog posts:', error);
		throw error;
	}
}

export async function getShopifyBlogArticles() {
	try {
		const res = await shopifyFetch<ShopifyBlogArticlesOperation>({
			query: getBlogPostsQuery,
			tags: [TAGS.blog],
		});

		const blogs = reshapeBlogs(removeEdgesAndNodes(res.body.data.blogs));

		return blogs.flatMap((blog) => blog.articles);
	} catch (error) {
		console.error('Failed to fetch blog posts:', error);
		throw error;
	}
}

export async function getShopifyBlogArticleByHandle({
	blogHandle,
	articleHandle,
}: {
	blogHandle: string;
	articleHandle: string;
}) {
	try {
		const res = await shopifyFetch<ShopifyBlogArticleByHandleOperation>({
			query: getBlogPostByHandleQuery,
			variables: { blogHandle, articleHandle },
			tags: [TAGS.blog],
		});

		const post = res.body.data.blogByHandle.articleByHandle;

		if (!post) {
			throw new Error(`Blog post with handle "${articleHandle}" not found.`);
		}

		// Ensure it matches the required format
		return post;
	} catch (error) {
		console.error('Failed to fetch blog post by handle:', error);
		throw error;
	}
}
