'use client';
import { useAtom } from 'jotai';
import { type ReactNode } from 'react';
import { selectedProductTagAtom } from '../SubscriptionListing/state/selectedProductTagAtom';

type ProductCardWrapperProps = {
	children: ReactNode;
	productTags: string[];
};

function ProductCardWrapper({ children, productTags }: ProductCardWrapperProps) {
	const [selectedTagId] = useAtom(selectedProductTagAtom);

	if (!selectedTagId || productTags.includes(selectedTagId)) {
		return children;
	}

	return null;
}

export default ProductCardWrapper;
