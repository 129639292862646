'use client';

import { useHydrateAtoms } from 'jotai/utils';
import { type WritableAtom } from 'jotai';
import { type ReactNode } from 'react';

type HydrateAtomsProps = {
	atomValues: Iterable<readonly [WritableAtom<unknown, [any], unknown>, unknown]>;
	children: ReactNode;
};

function HydrateAtoms({ atomValues, children }: HydrateAtomsProps) {
	useHydrateAtoms(new Map(atomValues));

	return children;
}

export default HydrateAtoms;
