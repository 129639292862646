'use client';

import type { Product, ProductList } from '@features/api/client';
import { formatMoney } from '@features/shared/utils/formatMoney';
import ProductCardWrapper from './ProductCardWrapper';
import { type Cart, type Product as ShopifyProductType } from '../../../../../api/shopify/types';
import ToggleCartItemButton from './ToggleCartItemButton';
import DiscountTag from '../../parts/DiscountTag/DiscountTag';
import useGetCart from '@features/webshop/products/data/useGetCart';

type ProductCardItemProps = {
	shopifyProduct: ShopifyProductType;
	cmsProduct: Product | ProductList | undefined;
	initalCart?: Cart;
};

function ProductCardItem({ shopifyProduct, cmsProduct, initalCart }: ProductCardItemProps) {
	const { data: cart, isRefetching } = useGetCart({ initialData: initalCart });
	const currentVariant = shopifyProduct.variants[0];
	if (!cmsProduct || !shopifyProduct || !currentVariant) return null;

	const lineItem = cart?.lines.find((line) => line.merchandise.product.id === shopifyProduct.id);
	const totalDiscount = lineItem?.discountAllocations.reduce(
		(acc, curr) => acc + parseFloat(curr.discountedAmount.amount),
		0
	);

	const compareAtPrice = lineItem?.cost?.compareAtAmountPerQuantity
		? formatMoney(lineItem?.cost?.compareAtAmountPerQuantity)
		: null;
	const price = formatMoney({
		amount: lineItem?.cost?.totalAmount?.amount || currentVariant.price.amount,
		currencyCode: currentVariant.price.currencyCode,
	});

	const totalAmount = parseFloat(lineItem?.cost?.totalAmount?.amount || currentVariant.price.amount);
	const compareAtAmount = parseFloat(lineItem?.cost?.compareAtAmountPerQuantity?.amount || '0');
	const shouldShowCompareAtPrice = !!compareAtPrice && compareAtAmount > totalAmount;

	return (
		<ProductCardWrapper productTags={shopifyProduct.tags}>
			<ToggleCartItemButton initalCart={cart} shopifyProduct={shopifyProduct}>
				<div className="flex min-h-10 w-full flex-col gap-1 sm:min-h-16">
					<div className="flex w-full items-center justify-between gap-3">
						<span className="flex items-center whitespace-nowrap text-base font-black !leading-none xs:text-xl sm:text-2xl">
							{cmsProduct.short_title || cmsProduct.title}
						</span>
						<span className="flex items-center gap-1 max-xs:self-end sm:gap-3">
							{shouldShowCompareAtPrice && (
								<span className="xs:text.lg text-base font-light !leading-none line-through sm:text-xl">
									{compareAtPrice}
								</span>
							)}

							<span className="text-xl font-black !leading-none xs:text-2xl sm:text-3xl">
								{price}
							</span>
						</span>
					</div>
					{lineItem && (
						<div className="flex gap-2">
							{lineItem?.discountAllocations.map(
								(discountAllocation) =>
									discountAllocation.title &&
									!!totalDiscount && (
										<DiscountTag
											key={discountAllocation.title}
											title={`${discountAllocation.title} (-${formatMoney({ amount: totalDiscount })})`}
										/>
									)
							)}
						</div>
					)}
				</div>
			</ToggleCartItemButton>
		</ProductCardWrapper>
	);
}

export default ProductCardItem;
