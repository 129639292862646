import { queryKeys } from 'settings/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { getCart } from '@features/api/shopify';
import type { Cart } from '@features/api/shopify/types';
import Cookies from 'js-cookie';

function useGetCart({ initialData }: { initialData?: Cart }) {
	const cartId = Cookies.get('cartId');

	return useQuery({
		queryKey: queryKeys.cart.base,
		queryFn: async () => {
			if (!cartId) return undefined;

			return await getCart(cartId);
		},
		initialData: initialData,
		enabled: !!cartId,
	});
}

export default useGetCart;
