function getHoursMinutesAndSeconds(secondsFull: number) {
	const hours = Math.floor(secondsFull / 3600);
	const minutes = Math.floor((secondsFull % 3600) / 60);
	const seconds = Math.floor(secondsFull % 60);

	return { hours, minutes, seconds };
}

export function formatDurationWithColonToMinutes(duration: number) {
	const { seconds, minutes } = getHoursMinutesAndSeconds(duration);

	let formattedSeconds = seconds.toString().padStart(2, '0');
	let formattedMinutes = minutes.toString().padStart(2, '0');

	return `${formattedMinutes}:${formattedSeconds}`;
}

type FormatDurationOptions = {
	withSeconds?: boolean;
	withHours?: boolean;
	withMinutes?: boolean;
};

const withoutTrailingDenominator = (duration: string, denominator: string) => {
	let formattedDuration = duration;

	// Remove the denominator from the beginning of the string
	if (formattedDuration.startsWith(denominator)) {
		formattedDuration = formattedDuration.slice(denominator.length);
	}

	// Remove the denominator from the end of the string
	if (formattedDuration.endsWith(denominator)) {
		formattedDuration = formattedDuration.slice(0, -denominator.length);
	}

	return formattedDuration;
};

export function formatDurationWithColon(
	duration: number,
	options: FormatDurationOptions = { withHours: true, withMinutes: true, withSeconds: false }
) {
	const { hours, minutes, seconds } = getHoursMinutesAndSeconds(duration);

	let formattedHours = hours.toString().padStart(2, '0');
	let formattedMinutes = minutes.toString().padStart(2, '0');
	let formattedSeconds = seconds.toString().padStart(2, '0');

	let formattedDuration = '';

	formattedDuration += options.withHours ? `${formattedHours}` : '';
	formattedDuration += options.withMinutes ? `:${formattedMinutes}` : '';
	formattedDuration += options.withSeconds ? `:${formattedSeconds}` : '';

	formattedDuration = withoutTrailingDenominator(formattedDuration, ':');

	return formattedDuration;
}

export function formatDurationWithSpace(duration: number) {
	const { hours, minutes } = getHoursMinutesAndSeconds(duration);

	let formattedHours = hours.toString().padStart(2, '0');
	let formattedMinutes = minutes.toString().padStart(2, '0');

	return `${formattedHours}h ${formattedMinutes}m`;
}
