'use client';
import type { CourseModuleProductPage } from '@features/api/client';
import { formatDurationWithColon, formatDurationWithSpace } from '@features/shared/utils/formatVideoDuration';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@features/webshop/shared/ui/Collapsible';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { IconBrandYoutube, IconChevronDown, IconChevronUp, IconClock, IconFile } from '@tabler/icons-react';
import { useState } from 'react';

type ProductInfoCourseModuleProps = {
	module: CourseModuleProductPage;
};

function ProductInfoCourseModule({ module }: ProductInfoCourseModuleProps) {
	const [isOpen, setIsOpen] = useState(false);
	const [parentRef] = useAutoAnimate();

	return (
		<Collapsible
			ref={parentRef}
			open={isOpen}
			onOpenChange={setIsOpen}
			className="border-b border-solid border-gray-200 last:border-0">
			<CollapsibleTrigger className="flex w-full items-center justify-between gap-5 p-5">
				<h3 className="flex items-center gap-2 text-base font-semibold text-neutral-800">
					{isOpen ? <IconChevronUp /> : <IconChevronDown />}
					{module.title}
				</h3>
				<div className="grid grid-cols-3 gap-4">
					<span className="flex w-28 gap-2">
						<span className="text-orange-700">
							<IconFile />
						</span>
						{module.number_of_text_lessons} lekcije
					</span>
					<span className="flex w-28 gap-2">
						<span className="text-secondary">
							<IconBrandYoutube />
						</span>
						{module.number_of_video_lessons} videa
					</span>
					<span className="flex w-28 gap-2">
						<span className="text-primary">
							<IconClock />
						</span>
						{formatDurationWithSpace(module.duration)}
					</span>
				</div>
			</CollapsibleTrigger>
			{isOpen && (
				<CollapsibleContent className="flex flex-col gap-4">
					{module.chapters.map((chapter) => {
						return (
							<div className="flex items-center justify-between px-6 pb-4" key={chapter.id}>
								<h4 className="text-sm font-normal text-neutral-800">{chapter.title}</h4>
								<span className="text-sm font-normal text-gray-200">
									{formatDurationWithColon(chapter.duration)}
								</span>
							</div>
						);
					})}
				</CollapsibleContent>
			)}
		</Collapsible>
	);
}

export default ProductInfoCourseModule;
