'use client';

import type { Cart, Product as ShopifyProductType } from '@features/api/shopify/types';
import useGetCart from '../../../../products/data/useGetCart';
import { IconCheck } from '@tabler/icons-react';
import useAddToCart from '../../../../products/data/useAddToCart';
import useRemoveFromCart from '../../../../products/data/useRemoveFromCart';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

type ToggleCartItemButtonProps = {
	initalCart?: Cart;
	children?: ReactNode;
	shopifyProduct: ShopifyProductType;
};

function RemoveButton({
	isLoading,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }) {
	if (isLoading) return <AddButton />;

	return (
		<button
			{...rest}
			suppressHydrationWarning
			className="flex h-6 min-h-6 w-6 min-w-6 items-center justify-center rounded-full border-[3px] border-solid border-gray-800 bg-gray-800 text-white shadow-[0_0_10px_0_rgba(0,0,0,0.10)] xs:h-7 xs:min-h-7 xs:w-7 xs:min-w-7">
			<IconCheck strokeWidth={3} className="text-bray-800 h-4 w-4 xs:h-5 xs:w-5" />
		</button>
	);
}

function AddButton({
	isLoading,
	...rest
}: ButtonHTMLAttributes<HTMLButtonElement> & { isLoading?: boolean }) {
	if (isLoading) return <RemoveButton />;

	return (
		<button
			{...rest}
			suppressHydrationWarning
			className="h-6 min-h-6 w-6 min-w-6 rounded-full border-[3px] border-solid border-gray-800 shadow-[0_0_10px_0_rgba(0,0,0,0.10)] xs:h-7 xs:min-h-7 xs:w-7 xs:min-w-7"></button>
	);
}

function ToggleCartItemButton({ initalCart, shopifyProduct, children }: ToggleCartItemButtonProps) {
	const shopifyProductVariant = shopifyProduct.variants[0];

	const { mutate: addToCart, isPending: isAddingToCart } = useAddToCart({ shopifyProduct });
	const { mutate: removeFromCart, isPending: isRemovingFromCart } = useRemoveFromCart();

	const isLoading = isAddingToCart || isRemovingFromCart;

	const { data: cart } = useGetCart({ initialData: initalCart });
	const line = cart?.lines.find((line) => line.merchandise.id === shopifyProductVariant?.id);

	if (!shopifyProductVariant) return null;

	if ((isLoading && line) || !line) {
		return (
			<span
				className="xs:border-b-[1.5px]-2 mb-3 flex cursor-pointer gap-4 border-b-[1.5px] border-dashed border-gray-800/60 pb-3 xs:mb-5 xs:pb-5 sm:gap-7"
				onClick={() => {
					addToCart([
						{
							quantity: 1,
							merchandiseId: shopifyProductVariant?.id,
						},
					]);
				}}>
				<AddButton isLoading={isAddingToCart} />
				{children}
			</span>
		);
	}
	return (
		<span
			className="xs:border-b-[1.5px]-2 mb-3 flex cursor-pointer gap-4 border-b-[1.5px] border-dashed border-gray-800/60 pb-3 xs:mb-5 xs:pb-5 sm:gap-7"
			onClick={() => {
				removeFromCart([line.id]);
			}}>
			<RemoveButton isLoading={isRemovingFromCart} />
			{children}
		</span>
	);
}

export default ToggleCartItemButton;
