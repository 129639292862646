/**
 * Usage:
 * import i18next from 'i18n'
 *
 * Then call translate like this:
 * i18next.t('hello.world', 'Fallback')
 *
 * See: https://www.i18next.com/ for more information
 */

import i18next from 'i18next';

import hr from './translations/hr.json';

i18next.init({
	lng: 'hr',
	supportedLngs: ['hr'],
	// debug: true,
	resources: {
		hr: {
			translation: hr,
		},
	},
});

// Update this to reflect what language your page should use
const lang = () => 'hr';

i18next.changeLanguage(lang());

export default i18next;
