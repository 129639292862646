/* eslint-disable no-unused-vars */
'use client';

import * as React from 'react';
import {
	type EmblaCarouselType as CarouselApi,
	type EmblaOptionsType as CarouselOptions,
	type EmblaPluginType as CarouselPlugin,
} from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { Button } from './Button';
import { cn } from '../../../shared/utils/utils';
import { BtnVariantEnum } from '@features/api/client';

type CarouselProps = {
	opts?: CarouselOptions;
	plugins?: CarouselPlugin[];
	orientation?: 'horizontal' | 'vertical';
	setApi?: (api: CarouselApi) => void;
};

type CarouselContextProps = {
	carouselRef: ReturnType<typeof useEmblaCarousel>[0];
	api: ReturnType<typeof useEmblaCarousel>[1];
	scrollPrev: () => void;
	scrollNext: () => void;
	canScrollPrev: boolean;
	canScrollNext: boolean;
	scrollTo: (_index: number) => void;
	scrollSnapList?: () => number[];
	selectedScrollSnap?: () => number;
} & CarouselProps;

const CarouselContext = React.createContext<CarouselContextProps | null>(null);

function useCarousel() {
	const context = React.useContext(CarouselContext);

	if (!context) {
		throw new Error('useCarousel must be used within a <Carousel />');
	}

	return context;
}

const Carousel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
	({ orientation = 'horizontal', opts, setApi, plugins, className, children, ...props }, ref) => {
		const [carouselRef, api] = useEmblaCarousel(
			{
				...opts,
				axis: orientation === 'horizontal' ? 'x' : 'y',
			},
			plugins
		);
		const [canScrollPrev, setCanScrollPrev] = React.useState(false);
		const [canScrollNext, setCanScrollNext] = React.useState(false);

		const onSelect = React.useCallback((api: CarouselApi) => {
			if (!api) {
				return;
			}

			setCanScrollPrev(api.canScrollPrev());
			setCanScrollNext(api.canScrollNext());
			api.scrollTo(api.selectedScrollSnap());
		}, []);

		const scrollPrev = React.useCallback(() => {
			api?.scrollPrev();
		}, [api]);

		const scrollNext = React.useCallback(() => {
			api?.scrollNext();
		}, [api]);

		const scrollTo = React.useCallback(
			(index: number) => {
				api?.scrollTo(index);
			},
			[api]
		);

		const handleKeyDown = React.useCallback(
			(event: React.KeyboardEvent<HTMLDivElement>) => {
				if (event.key === 'ArrowLeft') {
					event.preventDefault();
					scrollPrev();
				} else if (event.key === 'ArrowRight') {
					event.preventDefault();
					scrollNext();
				}
			},
			[scrollPrev, scrollNext]
		);

		React.useEffect(() => {
			if (!api || !setApi) {
				return;
			}

			setApi(api);
		}, [api, setApi]);

		React.useEffect(() => {
			if (!api) {
				return;
			}

			onSelect(api);
			api.on('reInit', onSelect);
			api.on('select', onSelect);

			return () => {
				api?.off('select', onSelect);
			};
		}, [api, onSelect]);

		return (
			<CarouselContext.Provider
				value={{
					carouselRef,
					api,
					opts,
					orientation: orientation || (opts?.axis === 'y' ? 'vertical' : 'horizontal'),
					scrollPrev,
					scrollNext,
					canScrollPrev,
					canScrollNext,
					scrollTo,
				}}>
				<div
					ref={ref}
					onKeyDownCapture={handleKeyDown}
					className={cn('relative', className)}
					role="region"
					aria-roledescription="carousel"
					{...props}>
					{children}
				</div>
			</CarouselContext.Provider>
		);
	}
);
Carousel.displayName = 'Carousel';

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const { carouselRef, orientation } = useCarousel();

		return (
			<div ref={carouselRef} className="overflow-hidden">
				<div
					ref={ref}
					className={cn(
						'flex',
						orientation === 'horizontal' ? '-ml-4' : '-mt-4 flex-col',
						className
					)}
					{...props}
				/>
			</div>
		);
	}
);
CarouselContent.displayName = 'CarouselContent';

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const { orientation } = useCarousel();

		return (
			<div
				ref={ref}
				role="group"
				aria-roledescription="slide"
				className={cn(
					'min-w-0 shrink-0 grow-0 basis-full',
					orientation === 'horizontal' ? 'pl-4' : 'pt-4',
					className
				)}
				{...props}
			/>
		);
	}
);
CarouselItem.displayName = 'CarouselItem';

const CarouselPrevious = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
	({ className, variant = BtnVariantEnum.Ghost, ...props }, ref) => {
		const { orientation, scrollPrev, canScrollPrev } = useCarousel();

		return (
			<Button
				ref={ref}
				variant={variant}
				color={'transparent'}
				className={cn(
					'relative h-24 w-24 hover:bg-transparent',
					orientation === 'horizontal' ? '' : '-top-12 left-1/2 -translate-x-1/2 rotate-90',
					className
				)}
				disabled={!canScrollPrev}
				onClick={scrollPrev}
				{...props}>
				<IconChevronLeft className="min-h-16 min-w-16" />
				<span className="sr-only">Previous slide</span>
			</Button>
		);
	}
);
CarouselPrevious.displayName = 'CarouselPrevious';

const CarouselNext = React.forwardRef<HTMLButtonElement, React.ComponentProps<typeof Button>>(
	({ className, variant = BtnVariantEnum.Ghost, ...props }, ref) => {
		const { orientation, scrollNext, canScrollNext } = useCarousel();

		return (
			<Button
				ref={ref}
				variant={variant}
				color={'transparent'}
				className={cn(
					'relative h-24 w-24 hover:bg-transparent',
					orientation === 'horizontal' ? '' : '-bottom-12 left-1/2 -translate-x-1/2 rotate-90',
					className
				)}
				disabled={!canScrollNext}
				onClick={scrollNext}
				{...props}>
				<IconChevronRight className="min-h-16 min-w-16" />
				<span className="sr-only">Next slide</span>
			</Button>
		);
	}
);
CarouselNext.displayName = 'CarouselNext';

const CarouselNavigation = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, children, ...props }, ref) => (
		<div ref={ref} className={cn('flex items-center justify-between', className)}>
			{children}
		</div>
	)
);
CarouselNavigation.displayName = 'CarouselNavigation';

const CarouselDots = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
	({ className, ...props }, ref) => {
		const { scrollTo, api } = useCarousel();
		const [currentSlideIndex, setCurrentSlideIndex] = React.useState<number | undefined>(0);

		React.useEffect(() => {
			api?.on('select', (currentApi) => {
				setCurrentSlideIndex(currentApi.selectedScrollSnap());
			});
		}, [api]);

		return (
			<div ref={ref} className="flex gap-1">
				{api
					?.scrollSnapList()
					.map((slideNumber, index) => (
						<Button
							variant="ghost"
							size="icon"
							onClick={() => scrollTo(index)}
							key={slideNumber}
							className={cn(
								'hover:bg-gray/90 h-[10px] w-[10px] rounded-full bg-gray-200',
								currentSlideIndex === index && 'w-10 bg-primary-blue hover:bg-primary-blue/90'
							)}
						/>
					))}
			</div>
		);
	}
);
CarouselDots.displayName = 'CarouselDots';

export {
	type CarouselApi,
	Carousel,
	CarouselContent,
	CarouselItem,
	CarouselPrevious,
	CarouselNext,
	CarouselNavigation,
	CarouselDots,
};
